<template>
  <v-app class="wrap">
    <AppBar />
    <v-container
      id="app"
      class="fill-height pa-0"
      fluid
      style="width: 100%; margin-bottom: 45px;"
    >
      <MainView class="fill-height" />
    </v-container>
    <Bottom class="ml-n3" />
  </v-app>
</template>

<script>
export default {
  name: "index",
  components: {
    AppBar: () => import("./core/AppBar"),
    MainView: () => import("./core/MainView"),
    // Advertise: () => import('./core/Advertise'),
    Bottom: () => import("./core/Bottom"),
  },
  data: () => ({
    alert: false,
    browserName: "Name",
  }),

  beforeCreate() {
    // function get_browser() {
    //   let ua = navigator.userAgent,
    //     tem,
    //     M =
    //       ua.match(
    //         /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
    //       ) || [];
    //   if (/trident/i.test(M[1])) {
    //     tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    //     return { name: "IE", version: tem[1] || "" };
    //   }
    //   if (M[1] === "Chrome") {
    //     tem = ua.match(/\bOPR|Edge\/(\d+)/);
    //     if (tem != null) {
    //       return { name: "Opera", version: tem[1] };
    //     }
    //   }
    //   M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
    //   if ((tem = ua.match(/version\/(\d+)/i)) != null) {
    //     M.splice(1, 1, tem[1]);
    //   }
    //   return {
    //     name: M[0],
    //     version: M[1],
    //   };
    // }

    // let browser = get_browser(); // browser.name = 'Chrome'

    // console.log("Browser Name : ", browser.version);
    // this.browserName = browser.version;
    // // this.alert = true
    // // http://localhost:8080/home/gift?id=adfljkrj3lkj1lk34j
    // console.log("Get Gift Params ....... : ", this.$route.params.gift);

    // // if(browser.name !=='Chrome' || browser.version <= 40) {
    // if (browser.version <= 40) {
    //   // window.parent.close()
    //   // location.href = 'edge://inappbrowser/close'
    //   // location.href = 'kakaotalk://inappbrowser/close'
    //   location.href = `intent://onethecon.com/home/gift?id=${present_id}#Intent;scheme=http;package=com.android.chrome;end`;
    // }

    /* [브라우저 ready 상태 정의 : 최초 호출 상태] */
    document.addEventListener("DOMContentLoaded", ready);
    function ready() {
      console.log("");
      console.log("[window ready] : [start]");
      console.log("");

      // [현재 접속된 url 정보 및 접속 브라우저 확인]
      // [카카오톡 인앱 브라우저 >> 안드로이드 모바일 기종 인 경우 >> 크롬 브라우저 이동 실시]
      var Url = location.href;
      var Agent = navigator.userAgent.toLowerCase();
      console.log("");
      console.log("[window ready] : [접속 Url] : " + Url);
      console.log("[window ready] : [접속 Agent] : " + Agent);
      console.log("");

      //alert(Agent);
      // if (Agent.includes("kakao")) { // 카카오, 인스타 인앱 브라우저로 실행 시킨 경우
      if (Agent.indexOf("kakao") > -1 || Agent.indexOf("instagram") > -1) {
        // 먼저, 카카오 인앱 브라우저 닫기
        if (Agent.includes("kakao")) {
          location.href = "kakaotalk://inappbrowser/close";
        }
        // 인스타 인앱 브라우저 닫기
        else if (Agent.includes("instagram")) {
          location.href = "instagram://inappbrowser/close";
        }
        // if (navigator.userAgent.match(/iPhone|iPad/i)) {
        //   // 아이폰 접속 경우
        //   console.log("");
        //   console.log("[window ready] : [접속 모바일] : " + "[아이폰]");
        //   console.log("");
        //   // 아이폰의 경우 현재 방법이 막혔습니다..
        // } 
        else {
          // 안드로이드 접속 경우
          console.log("");
          console.log("[window ready] : [접속 모바일] : " + "[안드로이드]");
          console.log("");
          // 크롬으로 새창 열기
          location.href =
            "intent://" +
            location.href.replace(/https?:\/\//i, "") +
            "#Intent;scheme=http;package=com.android.chrome;end";
        }
      }
    }
  }, // beforeCreate

  created() {
    let present_id;
    if (typeof this.$route.query.id !== "undefined") {
      console.log("Set LocalStorage.....................");
      present_id = this.$route.query.id;
      localStorage.setItem("Gift", present_id);
    }
  },
};
</script>

<style lang="scss">
.wrap {
  width: 100%;
  max-width: 395px;
  height: auto;
  min-height: 100vh;
  margin: 0 auto;
  position: relative;
}
.partner_position {
  position: absolute;
}

// .v-alert {
// position: fixed;
// bottom: 50%;
// margin: 0 auto;  // Without this the box extends the width of the page
// }
</style>
